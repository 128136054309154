export const API_KEY = `bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5`;
/* This is the original link to make api calls to the mars rover api to.
https://api.nasa.gov/planetary/apod?api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

https://api.nasa.gov/mars-photos/api/v1/rovers/perseverance/photos?earth_date=2021-2-18&camera=skycam&api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?earth_date=2015-6-3&api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

https://api.nasa.gov/mars-photos/api/v1/rovers/opportunity/photos?sol=1000&camera=pancam&api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?sol=1000&camera=fhaz&api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?sol=1000&page=2&api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

Mainfest API's -
https://api.nasa.gov/mars-photos/api/v1/manifests/perseverance/?api_key=bd0FD0AbSf4p0bQrvyDYPoleF38ynj8KejufG8V5

*/
