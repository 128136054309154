import React from 'react';
import './Rovers.css'
import axios from 'axios'
import { API_KEY } from '../../APIdataJSONS/.keys'
import RoverManifest from './RoverManifest'

export default function RoverSearch({
    state, setState, latestPhotos, hoverRover
}) {

    const handleRover = (e) => {
        const listedRovers = [...state.rovers]
        const selectedRover = listedRovers.filter(rover => e === rover)
        axios
            .get(`https://api.nasa.gov/mars-photos/api/v1/manifests/${e.toLowerCase()}/?api_key=${API_KEY}`)
            .then((res) => {
                const firstDay = res.data.photo_manifest.landing_date
                const lastDay = res.data.photo_manifest.max_date
                setState({
                    ...state,
                    rovers: selectedRover,
                    roverMessage: 'Reset Rovers',
                    roverSelected: true,
                    startDate: firstDay,
                    endDate: lastDay,
                    date: lastDay,
                })
            })
            .catch((err) => console.error(err))
    };



    const resetRovers = () => {
        latestPhotos()
     }

    const datePicker = (e) => {
        e.preventDefault()
        const dateChoice = e.target.value
        setState({
            ...state,
            date: dateChoice,
        })
    }

    const getRoverPics = (e) => {
        e.preventDefault()
        axios
            .get(`https://api.nasa.gov/mars-photos/api/v1/rovers/${state.rovers}/photos?earth_date=${state.date}&api_key=${API_KEY}`)
            .then((res) => {
                const images = []
                const imageObj = {
                    total: res.data.photos.length,
                    date: res.data.photos[0].earth_date,
                    imagesArray: []
                }

                if (imageObj.total > 1) {
                    for (let i = 0; images.length < imageObj.total; i++) {
                        let count = i
                        images.push(count)
                    }
                    images.forEach(n => {
                        const image = res.data.photos[n].img_src
                        const camera = res.data.photos[n].camera.full_name
                        const rover = res.data.photos[n].rover.name
                        imageObj.imagesArray.push({ image, camera, rover })
                    })
                } else {
                    const { photos } = res.data
                    for (let keys in photos) {
                        const image = photos[keys].img_src
                        const camera = photos[keys].camera.full_name
                        const rover = photos[keys].rover.name
                        imageObj.imagesArray.push({ image, camera, rover })
                    }
                }

                setState({
                    ...state,
                    marsPics: imageObj.imagesArray,
                    roverMessage: 'Pick a Rover',
                    rovers: ['Perseverance', 'Opportunity', 'Curiosity', 'Spirit'],
                    roverSelected: false,
                    date: imageObj.date,
                    imageTotal: imageObj.total
                })
            })
            .catch((err) => console.error(err))
    }

    return (
        <div className='rovers'>
            <div>
                <section className='title'>
                    {state.roverMessage === 'Pick a Rover'
                        ? <div className='pick-rover'>
                            <h4>{state.roverMessage}</h4>
                            <h5>Hover on the rover name for more detailed information</h5>
                        </div>
                        : <div className='reset-rover'>
                            <h4 onClick={resetRovers}>{state.roverMessage}</h4>
                        </div>
                    }

                    <div className='rover-select'>
                        {state.rovers.map((rover, idx) => {
                            return (
                                <div
                                    className='rover'
                                    value={rover}
                                    key={idx}
                                    onMouseEnter={hoverRover}
                                    onMouseLeave={() => { setState({ ...state, roverDataView: false }) }}
                                    onClick={() => handleRover(rover)}>{rover}
                                </div>
                            )
                        })}
                    </div>
                </section>
                    <RoverManifest state={state} />
            </div>
            <div>
                {state.roverMessage === 'Reset Rovers' &&
                    <section className='date'>
                    <form className='date-select'>
                        <h4>Pick a Day</h4>
                            <input
                                id='date'
                                type='date'
                                min={state.startDate}
                                max={state.endDate}
                                value={state.date}
                                onChange={datePicker}
                            />
                    </form>
                    <button className='rover-button' onClick={getRoverPics}>Get Images</button>
                    </section>
                }
            </div>
        </div>
    )
};
