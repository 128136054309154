import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_KEY } from '../../APIdataJSONS/.keys';
import Header from '../Header';
import Images from '../Images'
import ImageModal from '../Images/imageModal'
 import RoverSearch from '../Rovers';
import Telemetry from '../Telemetry'

export default function Mars() {

    const [state, setState] = useState({
        date: '',
        endDate: '',
        imageTotal: 0,
        imageDetail: '',
        imageView: false,
        marsPics: '',
        roverMessage: 'Pick a Rover',
        roverData: [],
        roverDataView: false,
        rovers: ['Perseverance', 'Opportunity', 'Curiosity', 'Spirit'],
        roverSelected: false,
        startDate: '',
    })

    const latestPhotos = () => {
        axios
            .get(
                `https://api.nasa.gov/mars-photos/api/v1/rovers/perseverance/latest_photos?api_key=${API_KEY}`
            )
            .then((res) => {
                const images = []
                const imageObj = {
                    total: res.data.latest_photos.length,
                    date: res.data.latest_photos[0].earth_date,
                    imagesArray: []
                }

                for (let i = 0; images.length < imageObj.total; i++) {
                    let count = i
                    images.push(count)
                }

                images.forEach(n => {
                    const image = res.data.latest_photos[n].img_src
                    const camera = res.data.latest_photos[n].camera.full_name
                    const rover = res.data.latest_photos[n].rover.name
                    imageObj.imagesArray.push({ image, camera, rover })
                })

                setState({
                    ...state,
                    marsPics: imageObj.imagesArray,
                    roverMessage: 'Pick a Rover',
                    rovers: ['Perseverance', 'Opportunity', 'Curiosity', 'Spirit'],
                    roverSelected: false,
                    date: imageObj.date,
                    imageTotal: imageObj.total
                })
            })
            .catch((err) => console.error(err))
    }

    const hoverRover = (e) => {
        const listedRovers = [...state.rovers]
        const selectedRover = listedRovers.filter(rover => e.target.innerHTML === rover)
        const selectRover = selectedRover.join().toLowerCase()
        axios
            .get(`https://api.nasa.gov/mars-photos/api/v1/manifests/${selectRover}/?api_key=${API_KEY}`)
            .then((res) => {
                console.log(res.data)
                const roverName = res.data.photo_manifest.name
                const firstDay = new Date (res.data.photo_manifest.landing_date).toDateString()
                const lastDay = new Date (res.data.photo_manifest.max_date).toDateString()
                const status = res.data.photo_manifest.status
                const totalPhotos = res.data.photo_manifest.total_photos
                setState({
                    ...state,
                    roverData: [roverName, firstDay, lastDay, status, totalPhotos],
                    roverDataView: true,
                })
            })
            .catch((err) => console.error(err))
    }

    useEffect(() => {
        latestPhotos()
         // eslint-disable-next-line
    }, []);


    return (
        <>
            <ImageModal
                state={state}
                setState={setState}
            />
            <Header />
            <Telemetry />
            <RoverSearch
                state={state}
                setState={setState}
                latestPhotos={latestPhotos}
                hoverRover={hoverRover}
            />
            <div className='images'>
                <Images
                    state={state}
                    setState={setState}
                    latestMarsPics={state.marsPics}
                    date={state.date}
                    imageTotal={state.imageTotal}
                />
            </div>
        </>
    );
}
