import React from 'react'
import './Telemetry.css'

export default function Telemetry() {

  return (
    <>
      <div className='mars-info'>
        <h1>Today on Mars</h1>
        <p>Data delivered in real time from the amazing folks at Nasa JPL-CalTech</p>
        <iframe title='MarsData' className='mars-today' allowFullScreen={true} src='https://eyes.nasa.gov/apps/orrery/#/mars' />
      </div>
    </>
  )
}