import React from 'react';
import { addDays } from 'date-fns/fp'
import './Images.css'

export default function Images({ state, setState, latestMarsPics, date, imageTotal }) {
    let images = []
    const total = imageTotal
    const addDay = addDays(1)
    const captureDate = new Date(date)
    const returnDate = addDay(captureDate).toDateString()

    for (let index in latestMarsPics) {
        const image = latestMarsPics[index].image
        const camera = latestMarsPics[index].camera
        const rover = latestMarsPics[index].rover

        if (images.indexOf(latestMarsPics[index].image) === -1) {
            images.push([image, camera, rover])
        }
    }


    const imageInfo = (e) => {
        const expandedImage = e.target
        setState({
            ...state,
            imageDetail: expandedImage,
            imageView: true,
        })
    }

    return (
        <div>
            {state.roverSelected === false && <div className='image-info' >
                {total > 1 ?
                    <p>{imageTotal} captured images were sent back on {returnDate} </p>
                    : total < 1 ?
                            <p>No captured images were sent back on  {returnDate}, please try a a different day. </p>
                        : <p>{imageTotal} captured image was sent back on {returnDate} </p>
                }
                </div>}
            <div className='image'>
                <div>
                    {images.map((image, id) =>
                        <figure key={id} >
                            <img src={image[0]} alt='Visuals from mars' onClick={imageInfo} />
                            <figcaption>Image captured by the {image[1]} on the {image[2]} rover </figcaption>
                        </figure>
                    )}
                </div>
            </div>

        </div>
    );
};
