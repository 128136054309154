import React from 'react'
import './RoverManifest.css'

export default function RoverManifest({ state }) {

  console.log(state.roverData)

  return (
    <>
      <div>
        {state.roverDataView && state.roverMessage === 'Pick a Rover' && <div className='rover-modal'>
          <h3>{state.roverData[0]} Rover</h3>
          <p>Start of Mission: {state.roverData[1]} </p>
          <p>Last Day of Mission: {state.roverData[2]} </p>
          <p>Mission Status: {state.roverData[3]}</p>
          <p>Total Images: {state.roverData[4]}</p>
        </div>}
      </div>
    </>
  )
}