import React from 'react';
import './Header.css'

export default function Header() {

    return (
        <>

        <div className='header'>
            <h1>A NASA Mars Rovers Image Project</h1>
            <p>Built by Melody McClure</p>
            <ul>
                <li>Search from any of the cameras on any of the four Mars rovers in the Nasa Database using one of the NASA API's.</li>
                <li>Begin by choosing the rover. Then pick a date from the available service dates for that rover to see all the images captured on that day.</li>
                <li>Some days have hundreds of images, others have as few as one. </li>
                <li>If you want to start over, just click 'Reset Rovers' to begin again.</li>
                <li>Click on any image to enlarge it.</li>
                <li>The initial images you see are from the latest images sent by the Mars Perseverance Rover</li>
            </ul>
            <h3>Thanks for visiting! I hope you enjoy my site!</h3>
                <p>Developers check out the <a href='https://github.com/MelodyLayne/MarsRoverPicApp' target='blank' rel='noopener no-referrer' >GitHub Repo</a> and if you catch a bug feel free to open an issue!</p>
        </div>

        </>
    );
};
