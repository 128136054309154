import React from 'react';
import '../Mars/Mars.css'

export default function ImageModal({ state, setState }) {

  const hideModal = () => {
    setState({
      ...state,
      imageView: false,
    })
  }
  return (
    <>
      {state.imageView && <div className='image-modal' >
        <p id='hide-modal' onClick={hideModal}>Close Image</p>
        <img id='modal-image' src={state.imageDetail.src} alt={state.imageDetail.alt}/>
      </div>}
    </>
  )
}